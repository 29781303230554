.largeWidget {
    flex: 2;
    padding: 20px;
  }
  
  .largeWidgetTitle {
    font-size: 32px;
    font-weight: 400;
  }
  .largeWidgetTable {
    width: 80vw;
    border-spacing: 20px;
    
  }
  .largeWidgetTable td {
    border: 1px solid #000; /* Add this property to create borders inside the table cells */
    padding: 10px; /* Add padding for better spacing */
  }
  .largeWidgetHeader {
    text-align: left;
    
  }
  
  
  .largeWidgetDate,
  .largeWidgetUser,
  .largeWidgetAmount,
  .largeWidgetPhone,
  .largeWidgetPlan,
  .largeWidgetEmail,
  .largeWidgetStatus {
    font-weight: 300;
  }
  .largeWidgetButton {
    padding: 5px 7px;
    border: none;
    border-radius: 10px;
  }
  .largeWidgetButton.Approved {
    background-color: #90ee90;
    color: green;
  }
  .largeWidgetButton.Declined {
    background-color: #ffcccb;
    color: red;
  }
  .largeWidgetButton.Pending {
    background-color: lightblue;
    color: blue;
  }
  /* Add a media query for small screens */
  @media (max-width: 768px) {
    .largeWidgetTable {
      width: 100%; /* Make the table take up the full width of the screen */
      overflow-x: auto; /* Add horizontal scrollbar when necessary */
    }
  
    .largeWidgetHeader {
      padding: 10px; /* Adjust the padding for header cells on small screens */
    }
  }
